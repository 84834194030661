<template>
  <div class="edit-password user-wrapper">
    <div class="user-header">修改密码</div>
    <div class="user-container justify-center">
      <Form ref="form" :model="form" :rules="rules" label-width="80px">
        <FormItem label="旧密码" prop="oldpass">
          <Input v-model="form.oldpass" placeholder="请输入旧密码" type="password" show-password autofocus></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPass">
          <Input
            v-model="form.newPass"
            placeholder="8-24位，至少包含数字、字母或特殊字符中两种方式"
            type="password"
            show-password
          ></Input>
        </FormItem>
        <FormItem label="确认密码" prop="checkPass">
          <Input
            v-model="form.checkPass"
            placeholder="8-24位，至少包含数字、字母或特殊字符中两种方式"
            type="password"
            show-password
          ></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" style="width: 96px" @click="onSubmit">保存</Button>
          <Button style="width: 96px" @click="onReset">取消</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
/**
 * @desc 修改密码
 */

import { Form, FormItem, Button, Input } from 'element-ui';
import { resetPassword } from '@/api/user';

export default {
  components: {
    Form,
    FormItem,
    Button,
    Input,
  },
  data() {
    const validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('旧密码不能为空'));
        return;
      }
      if (this.form.newPass !== '') {
        this.$refs.form.validateField('newPass');
      }
      if (this.form.newPass !== '' && this.form.checkPass !== '') {
        this.$refs.form.validateField('checkPass');
      }
      callback();
    };
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('新密码不能为空'));
        return;
      }
      if (value === this.form.oldpass) {
        callback(new Error('新密码与旧密码不能相同'));
        return;
      }
      const re =
        /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^0-9a-zA-Z]))[^\s\u4e00-\u9fa5]+)$/;
      if (value.length < 8 || value.length > 24 || !re.test(value)) {
        callback(new Error('8-24位，至少包含数字、字母或特殊字符中两种方式'));
        return;
      }
      if (/<.*>/.test(value)) {
        callback(new Error('不允许输入非法字符'));
        return;
      }
      if (this.form.checkPass !== '') {
        this.$refs.form.validateField('checkPass');
      }
      callback();
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'));
        return;
      }
      if (value !== this.form.newPass) {
        callback(new Error('确认密码与新密码不一致!'));
        return;
      }
      callback();
    };

    return {
      form: {
        oldpass: '',
        newPass: '',
        checkPass: '',
      },
      rules: {
        oldpass: [{ validator: validateOldPass, trigger: 'blur' }],
        newPass: [{ validator: validateNewPass, trigger: 'blur' }],
        checkPass: [{ validator: validateCheckPass, trigger: 'blur' }],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          resetPassword({
            oldPassword: this.form.oldpass,
            password: this.form.newPass,
            confirmPassword: this.form.checkPass,
          }).then(() => {
            // console.log(result);
            this.$message.success('修改密码成功');
          });
        } else {
          return false;
        }
      });
    },
    onReset() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../user.scss';

.edit-password {
  ::v-deep .el-form-item {
    margin-bottom: 32px;

    &:last-child {
      margin-top: 72px;
    }

    .el-form-item__label {
      color: #6d7171;
      font-size: 16px;
      font-weight: 400;
    }
  }
  ::v-deep .el-input {
    width: 450px;
  }
}
</style>
